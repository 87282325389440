<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Kullanıcı Ekle</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
              class="p-2"
              autocomplete="off"
              enctype="multipart/form-data"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Ad Soyad">
                      <b-form-input
                        class="rounded-0"
                        ref="ad_soyad"
                        v-model="form.ad_soyad"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Kullanıcı Adı ( Email )" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group label="Kullanıcı Adı ( Email )">
                      <b-form-input
                        class="rounded-0"
                        ref="email"
                        v-model="form.email"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Şifre" :rules="userSifreControl" v-slot="validationContext">
                    <b-form-group label="Şifre">
                      <b-input-group class="border-right">
                        <b-form-input
                          class="rounded-0"
                          ref="sifre"
                          v-model="form.sifre"
                          :type="passwordFieldType"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Telefon" label-for="telefon">
                    <b-form-input class="rounded-0" id="telefon" name="telefon" ref="telefon" v-model="form.telefon" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <validation-provider name="Role" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group
                      label="Role Seçiniz"
                      label-for="role_adi"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    >
                      <v-select
                        v-model="form.role_k_no"
                        :options="roller"
                        :reduce="(role) => role.k_no"
                        label="role_adi"
                        :clearable="true"
                        class="invoice-filter-select d-block"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Profil Resmi" label-for="role_adi">
                    <b-form-file
                      class="rounded-0"
                      placeholder="Bir resim seçiniz"
                      drop-placeholder="Drop file here..."
                      no-drop
                      v-model="profile"
                      accept="image/jpeg, image/png, image/jpg"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-between align-items-center py-1">
                <label for="statu">Kullanım Durumu</label>
                <b-form-checkbox id="statu" :checked="form.statu" v-model="form.statu" switch />
              </div>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block @click="hide">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { POSITION } from 'vue-toastification';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, watch, toRefs } from '@vue/composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    firma_k_no: {
      type: String,
      required: false,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.master = ref('user');
    expo.k_no = ref(null);
    expo.roller = ref([]);
    expo.profile = ref(null);
    expo.passwordFieldType = ref('password');
    expo.form = ref({
      _id: null,
      k_no: null,
      ad_soyad: null,
      email: null,
      sifre: null,
      telefon: null,
      role_k_no: null,
      statu: true,
    });

    const { updateData, firma_k_no } = toRefs(props);

    expo.passwordToggleIcon = computed(() => {
      return expo.passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    });

    expo.togglePasswordVisibility = () => {
      expo.passwordFieldType.value = expo.passwordFieldType.value === 'password' ? 'text' : 'password';
    };
    expo.userSifreControl = computed(() => {
      if (expo.form.value.k_no != null) {
        return { required: false };
      } else {
        return { required: true, min: 4 };
      }
    });

    const handlerRoller = async () => {
      await store.dispatch('rolleListele');
      expo.roller.value = await store.getters.getRoller;
    };

    handlerRoller();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.resetForm = () => {
      expo.form.value = {
        _id: null,
        k_no: null,
        firma_k_no: null,
        ad_soyad: null,
        email: null,
        sifre: null,
        telefon: null,
        role_k_no: null,
        statu: true,
      };
      expo.show.value = false;
      context.refs.ad_soyad.focus();
    };
    expo.onSubmit = () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });

      if (expo.profile.value !== null) {
        formData.append('profile', expo.profile.value, expo.profile.value.name);
      }

      if (firma_k_no.value != null) {
        formData.append('firma_k_no', firma_k_no.value);
      }

      const base_url = expo.form.value.k_no == null ? 'kullaniciEkle' : 'kullaniciGuncelle';
      store.dispatch(base_url, formData).then((res) => {
        if (res.data.success === true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: expo.form.value.k_no == null ? 'Ekleme başarılı.' : 'Güncelleme Başarılı.',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          expo.resetForm();
        }
      });
    };

    watch(updateData, (val) => {
      console.log(val);
      expo.form.value = { ...val };
    });
    return { ...expo };
  },

  // watch: {
  //   updateData(val) {
  //     if (val != null) {
  //       this.form._id = val._id;
  //       this.form.firma_k_no = val.firma_k_no;
  //       this.form.ad_soyad = val.ad_soyad;
  //       this.form.email = val.email;
  //       this.form.telefon = val.telefon;
  //       this.form.role_k_no = val.role_k_no;
  //       this.form.statu = val.statu;
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
